<script>


export default {
    name: "AdministradorDeVacantes",
    data() {
        return {
            listaDeVacantes: [],
            filtroCargoEmpresa: "",
            filtroUbicacion: "",
            selectedVacante: {}
        }
    },
    mounted() {
        this.getListaDeVacantes();
    },
    methods: {
        async getListaDeVacantes() {
            const response = await this.$store.getters.fetchGet({ path: `Vacante/all/active` })
            this.listaDeVacantes = await response.json();
            this.$router.push({ name: 'InformacionVacante', params: { id: this.listaDeVacantes[0].id } })
        },
        calcularDiferenciaDeTiempo(fecha) {
            const fechaObj = new Date(fecha);
            const ahora = new Date();
            const diferenciaEnMilisegundos = ahora - fechaObj;

            const minutos = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
            const horas = Math.floor(minutos / 60);
            const dias = Math.floor(horas / 24);
            const semanas = Math.floor(dias / 7);
            const meses = Math.floor(dias / 30);
            const años = Math.floor(meses / 12);

            if (años > 0) {
                return `Hace ${años} ${años === 1 ? 'año' : 'años'} `;
            } else if (meses > 0) {
                return `Hace ${meses} ${meses === 1 ? 'mes' : 'meses'}`;
            } else if (semanas > 0) {
                return `Hace ${semanas} ${semanas === 1 ? 'semana' : 'semanas'}`;
            } else if (dias > 0) {
                return `Hace ${dias} ${dias === 1 ? 'día' : 'días'}`;
            } else if (horas > 0) {
                return `Hace ${horas} ${horas === 1 ? 'hora' : 'horas'}`;
            } else {
                return `Hace ${minutos} ${minutos === 1 ? 'minuto' : 'minutos'}`;
            }
        }
    },
    computed: {
        filtradoDeVacantes() {
            return this.listaDeVacantes.filter(vacante => {
                return (
                    vacante.cargo.nombre.toLowerCase().includes(this.filtroCargoEmpresa.toLowerCase()) ||
                    vacante.cargo.sucursal.empresa.nombre.toLowerCase().includes(this.filtroCargoEmpresa.toLowerCase())
                )
                    && (
                        vacante.pais.descripcion.toLowerCase().includes(this.filtroUbicacion.toLowerCase()) ||
                        vacante.municipio.descripcion.toLowerCase().includes(this.filtroUbicacion.toLowerCase())
                    )
            })
        }
    }
}


</script>

<template>
    <div class="container-fluid">
        <card class="py-3 px-2 m-2 ">
            <div class="d-flex">
                <div class="d-flex card-vacantes">
                        <b-form-input v-model="filtroCargoEmpresa" type="text" placeholder="Filtrar por cargo o empresa">
                        </b-form-input>
    
                        <b-form-input v-model="filtroUbicacion" type="text" placeholder="Filtrar por Ciudad, Provincia, estado">
                        </b-form-input>
                </div>
                <div class="container-100 ml-4">
                    <!-- Espacio para mas filtros -->
                </div>
            </div>
        </card>

        <div class="container-slider px-3">
            <card class="card-vacantes overflow-75">
                <router-link
                v-for="vacante in filtradoDeVacantes" :key="vacante.id"
                :to="{ name: 'InformacionVacante', params: {id: vacante.id}}"
                class="d-flex m-0 p-3 card-vacante pointer" :class="{ 'active': $route.params.id == vacante.id }">
                    <figure>
                        <img :src="vacante.cargo.sucursal.empresa.urlLogo" alt="" width="100">
                    </figure>
                    <div class="content">
                        <h3>{{ vacante.cargo.nombre }}</h3>
                        <p>
                            <strong>
                                {{ vacante.cargo.sucursal.empresa.nombre }}
                            </strong>
                        </p>
                        <p>
                            {{ vacante.pais.descripcion }},
                            {{ vacante.municipio.descripcion }},
                            ( Presencial, {{ vacante.jornadaLaboral.name }} )
                        </p>
                        <p></p>
                        <p>{{ vacante.salario }} / Mes</p>
                        <div class="d-flex justify-content-end">
                            <p>{{ calcularDiferenciaDeTiempo(vacante.fechaDeCreacion) }}</p>
                        </div>
                    </div>
                </router-link>
            </card>
            <section 
            
            class="container-100 overflow-75">
                <router-view></router-view>
            </section>
        </div>

    </div>
</template>

<style scoped>
p {
    margin: 0 0 0.5rem 0;
}

.card-vacantes {
    min-width: 500px;
}

.overflow-75 {
    overflow-y: auto;
    height: 75vh;
}

.card-vacante:hover {
    background-color: #173463;

}
.card-vacante.active {
    background-color: #20498b;

}
.card-vacante .content {
    width: 100%;
    padding: 0 .8rem
}

.container-slider {
    display: flex;
    justify-content: center;
}

.container-100 {
    width: 100%;
}

</style>